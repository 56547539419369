import { useHead } from '#imports'
import type { Ref } from 'vue'

import type { ArticlePageContent } from '@backmarket/http-api/src/api-specs-content/models/article-page-content'

import { buildHead } from '~/scopes/cms/composables/useCMSHead'
import { useAddCanonicalLink } from '~/scopes/seo/composables/useAddCanonicalLink'

import { useArticlePageStructuredData } from './useArticleStructuredData'

export const CATEGORY_META_TAG = 'og:article:section'
export const IMAGE_ALT_META_TAG = 'og:image:alt'

export function useArticleHead(article: Ref<ArticlePageContent | null>) {
  useArticlePageStructuredData(article)

  if (!article.value) return

  const head = buildHead(article)

  const banner = article.value.banner?.image

  if (banner?.alt) {
    head.meta.push({
      hid: IMAGE_ALT_META_TAG,
      name: IMAGE_ALT_META_TAG,
      content: banner.alt,
    })
  }

  const lastCategory = article.value.categories?.slice(-1)[0]
  const categoryName = lastCategory.name

  if (categoryName) {
    head.meta.push({
      hid: CATEGORY_META_TAG,
      name: CATEGORY_META_TAG,
      content: categoryName,
    })
  }

  useHead(head)

  useAddCanonicalLink()
}
