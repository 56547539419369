import { type Ref } from 'vue'

import { useHandleFetchError } from '../../../cms/composables/useHandleFetchError'

export function useHandleArticleFetchErrors({
  contentErrorRef,
  votesErrorRef,
}: {
  contentErrorRef: Ref<{
    statusCode: number
    message: string
  } | null>
  votesErrorRef: Ref<{
    statusCode: number
    message: string
  } | null>
}) {
  useHandleFetchError(contentErrorRef)

  if (votesErrorRef.value?.statusCode !== 404) {
    useHandleFetchError(votesErrorRef)
  }
}
