<template>
  <p class="body-1" data-test="article-read-time">
    {{ i18n(translations.articleReadTime, { count: readTime }) }}
  </p>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { ArticlePageContent } from '@backmarket/http-api/src/api-specs-content/models/article-page-content'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './ReadTime.translations'
import { countArticleWords, readTimeInMinutes } from './ReadTime.utils'

const props = defineProps<{ content: ArticlePageContent }>()

const i18n = useI18n()

// if the article wordCount is provided use it, if not count the words of RichText elements
const readTime = computed(() => {
  if (!props.content) return 0
  if (props.content.wordCount) return readTimeInMinutes(props.content.wordCount)

  const wordCount = countArticleWords(props.content)

  return readTimeInMinutes(wordCount)
})
</script>
