export default {
  callToVote: {
    id: 'buying_guide_vote',
    defaultMessage: 'Did you find this article useful?',
  },
  articleVoteYes: {
    id: 'buying_guide_vote_yes',
    defaultMessage: 'Yes',
  },
  articleVoteNo: {
    id: 'buying_guide_vote_no',
    defaultMessage: 'No',
  },
}
