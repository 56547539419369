<template>
  <RevBackdrop
    v-if="listOpen"
    class="z-10 hidden md:block"
    data-test="article-backdrop"
    @click="onHandleDisplay"
  />

  <div
    v-show="listOpen"
    class="bg-surface-default-low fixed top-0 z-10 h-full w-full overflow-scroll md:rounded-l-lg md:right-0 md:w-[380px]"
    data-test="article-table-of-content"
  >
    <div class="heading-3 flex items-center pb-20 pt-16">
      <p class="w-full text-center">
        {{ i18n(translations.jumpTo) }}
      </p>

      <RevButtonIcon
        data-test="close-table-of-content"
        :icon="IconCross"
        variant="secondary"
        @click="onHandleDisplay"
      />
    </div>

    <RevDivider />

    <RevList :has-external-borders="false">
      <NuxtLink
        v-for="({ title, headingType }, index) in headings"
        :key="`${title}-${headingType}-${index}`"
        data-test="article-table-of-content-link"
        :to="`#${textToTarget(title, headingType)}`"
        @click="onHandleDisplay($event, textToTarget(title, headingType))"
      >
        <RevListItemInteractive>
          <template #prefix>
            <div
              v-if="textToTarget(title, headingType) === activeTitle"
              class="bg-static-info-max absolute left-0 top-0 h-full w-8"
              data-test="article-table-of-content-highlight"
            />
          </template>

          <template #label>
            <span
              class="body-1 inline-flex"
              :class="{
                'ml-16': headingType === 'heading-3',
                'body-1-bold': textToSlug(title, headingType) === activeTitle,
              }"
            >
              {{ title }}
            </span>
          </template>

          <template #suffix>
            <IconChevronRight />
          </template>
        </RevListItemInteractive>
      </NuxtLink>
    </RevList>
  </div>

  <RevButton
    v-if="!listOpen && showToggle"
    class="shadow-long !fixed bottom-0 left-1/2 z-10 mb-16 -translate-x-1/2 md:mb-0 md:ml-[493px] md:-translate-y-1/2"
    data-test="article-open-table-of-content"
    :icon="IconListView1"
    variant="primary"
    @click="onHandleDisplay"
  >
    <div class="flex">
      <IconListView1 class="mr-4" />
      {{ i18n(translations.jumpTo) }}
    </div>
  </RevButton>
</template>

<script setup lang="ts">
import { useRouter } from '#imports'
import { type Ref, onMounted, onUnmounted, ref, watch } from 'vue'

import type { _Block } from '@backmarket/http-api/src/api-specs-content/models/block'
import { textToSlug } from '@backmarket/nuxt-layer-cms/utils/textToSlug'
import { textToTarget } from '@backmarket/nuxt-layer-cms/utils/textToTarget'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { urlHashToObject } from '@backmarket/utils/url/urlHashToObject'
import { RevBackdrop } from '@ds/components/Backdrop'
import { RevButton } from '@ds/components/Button'
import { RevButtonIcon } from '@ds/components/ButtonIcon'
import { RevDivider } from '@ds/components/Divider'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'
import { IconChevronRight } from '@ds/icons/IconChevronRight'
import { IconCross } from '@ds/icons/IconCross'
import { IconListView1 } from '@ds/icons/IconListView1'
import { useElementVisibility } from '@vueuse/core'

import translations from './TableOfContent.translations'
import { findTitlesInBlocks } from './TableOfContent.utils'

const props = defineProps<{ blocks: _Block[]; showToggle: boolean }>()

const i18n = useI18n()
const router = useRouter()

const listOpen = ref<boolean>(false)
const activeTitle = ref<string>('')
const headings = findTitlesInBlocks(props.blocks)

const visibleTitles = ref<Record<string, Ref<boolean>>>({})

const previousY = ref<number>(0)
const scrollDirection = ref<'up' | 'down'>('down')

function determineScrollDirection() {
  const currentY = window.scrollY
  scrollDirection.value = currentY > previousY.value ? 'down' : 'up'
  previousY.value = currentY
}

function handleScroll(targetId: string, behavior?: 'smooth' | 'instant') {
  const titleIndexes = { targetIndex: 0, activeIndex: 0 }
  for (let i = 0; i < headings.length; i += 1) {
    const titleTarget = textToTarget(headings[i].title, headings[i].headingType)
    if (titleTarget === targetId) titleIndexes.targetIndex = i
    else if (titleTarget === activeTitle.value) titleIndexes.activeIndex = i
  }

  // offset header height when scrolling upwards
  let headerHeight = 0
  if (titleIndexes.targetIndex < titleIndexes.activeIndex) {
    const headerElement = document.getElementById('header')
    headerHeight = headerElement?.offsetHeight || 0
  }

  const element = document.getElementById(targetId)
  if (element) {
    window.scrollTo({
      top: element.getBoundingClientRect().top + window.scrollY - headerHeight,
      behavior,
    })
  }
}

function onHandleDisplay(event: Event, targetId?: string) {
  // we force smooth scroll behaviour
  if (event) event.preventDefault()
  listOpen.value = !listOpen.value

  if (listOpen.value) document.body.classList.add('overflow-hidden')
  else document.body.classList.remove('overflow-hidden')

  if (targetId) {
    router.replace({ hash: `#${targetId}` })
    handleScroll(targetId, 'smooth')
  }
}

onMounted(() => {
  headings.forEach((heading) => {
    const headingId = textToTarget(heading.title, heading.headingType)
    const htmlElement = document.getElementById(headingId)

    if (htmlElement) {
      visibleTitles.value[headingId] = useElementVisibility(htmlElement)
    }
  })

  document.addEventListener('scroll', determineScrollDirection)

  const { target }: { scroll?: boolean; target?: string } = urlHashToObject(
    router.currentRoute.value.hash,
  )

  if (target?.length) handleScroll(textToTarget(target[0]), 'instant')
})

onUnmounted(() => {
  document.removeEventListener('scroll', determineScrollDirection)
})

watch(
  visibleTitles,
  (newTitleStatus) => {
    if (!newTitleStatus) return

    let activeTitleIndex = 0
    let index = 0
    for (const [titleId, titleVisible] of Object.entries(newTitleStatus)) {
      if (titleVisible) {
        activeTitle.value = titleId

        return
      }

      if (activeTitle.value === titleId) activeTitleIndex = index
      index += 1
    }

    // used to handle cases where the title is outside the user's viewport, but we are still within the
    // bounds of that title's article section
    if (scrollDirection.value === 'up') {
      activeTitle.value = Object.keys(newTitleStatus)[activeTitleIndex - 1]
    } else {
      activeTitle.value = Object.keys(newTitleStatus)[activeTitleIndex]
    }
  },
  { deep: true, immediate: true },
)
</script>
