import { navigateTo, useRoute, useRouteParams } from '#imports'
import { type MaybeRefOrGetter, toValue } from 'vue'

import type { ArticlePageContent } from '@backmarket/http-api/src/api-specs-content/models/article-page-content'
import { merge } from '@backmarket/utils/object/merge'

export function useHandleIncorrectRouteParams(
  contentRef: MaybeRefOrGetter<ArticlePageContent | null>,
) {
  const content = toValue(contentRef)

  if (!content || content.categories.length < 1) {
    return
  }

  const articleCategories = content.categories
  const { slug: referenceCategory } =
    articleCategories[articleCategories.length - 1]

  const { category: routeCategory, pageName } = useRouteParams()

  const isValidCategory =
    !!referenceCategory &&
    !!routeCategory &&
    referenceCategory === routeCategory

  if (!isValidCategory) {
    const redirect = merge({}, useRoute(), {
      params: {
        pageName,
        category: referenceCategory,
      },
    })

    void navigateTo(redirect, { redirectCode: 301 })
  }
}
