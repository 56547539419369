export default {
  copyLink: {
    id: 'article_banner_copy_link',
    defaultMessage: 'Copy link',
  },
  shareX: {
    id: 'article_banner_share_x',
    defaultMessage: 'Share on X',
  },
  shareFacebook: {
    id: 'article_banner_share_facebook',
    defaultMessage: 'Share on Facebook',
  },
  shareLinkedIn: {
    id: 'article_banner_share_linkedIn',
    defaultMessage: 'Share on LinkedIn',
  },
  shareArticleLabel: {
    id: 'article_banner_share_article_label',
    defaultMessage: 'Share article',
  },
  toastCloseAlternativeText: {
    id: 'article_banner_share_toast_close_alternative_text',
    defaultMessage: 'Close',
  },
  toastTitle: {
    id: 'article_banner_share_toast_title',
    defaultMessage: 'Link copied to clipboard!',
  },
  toastDescription: {
    id: 'article_banner_share_toast_description',
    defaultMessage: 'Freddy said if I believe I can fly, then I can fly!',
  },
} as const
