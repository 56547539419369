import type { ArticlePageContent } from '@backmarket/http-api/src/api-specs-content/models/article-page-content'
import type { RichTextProps } from '@backmarket/http-api/src/api-specs-content/models/rich-text-content'
import type { Block, Text } from '@contentful/rich-text-types'
import type { Inline } from '@contentful/rich-text-types/dist/types/types'

const WORDS_PER_MINUTE = 200
const WORDS_PER_SECOND = WORDS_PER_MINUTE / 60
const SECONDS_IN_MINUTE = 60
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60
const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24
const SECONDS_IN_MONTH = SECONDS_IN_DAY * 30
const SECONDS_IN_YEAR = SECONDS_IN_DAY * 365

export function readTimeInMinutes(wordCount: number) {
  const readTime = wordCount / WORDS_PER_MINUTE

  let minutes = Math.floor(readTime)
  const decimal = ((readTime * 10) % 10) / 10
  const seconds = decimal * 60

  // since we only display minutes, round up the seconds if applicable
  if (seconds > 30) minutes += 1
  if (minutes === 0) minutes = 1

  return minutes
}

// https://www.digi.com/resources/documentation/digidocs/90001488-13/reference/r_iso_8601_duration_format.htm
export function secondsToDuration(durationInSeconds: number) {
  if (durationInSeconds === 0) return 'P0S'

  const timePeriods: [string, number][] = [
    ['Y', SECONDS_IN_YEAR],
    ['M', SECONDS_IN_MONTH],
    ['D', SECONDS_IN_DAY],
    ['H', SECONDS_IN_HOUR],
    ['M', SECONDS_IN_MINUTE],
    ['S', 1],
  ]

  let duration = 'P'
  let remainder = durationInSeconds
  let timeSeparatorAdded = false

  timePeriods.forEach(([sign, seconds], index) => {
    const value = Math.floor(remainder / seconds)
    if (!value) return

    remainder %= seconds

    // add the "T" delimiter to separate the Date & time sections
    if (index > 2 && !timeSeparatorAdded) {
      duration += 'T'
      timeSeparatorAdded = true
    }

    duration += `${value}${sign}`
  })

  return duration
}

export function wordCountToISO8601Duration(wordCount: number) {
  const readTimeSeconds = wordCount / WORDS_PER_SECOND

  // we're using seconds as it's the lowest unit within the ISO8601 duration format
  return secondsToDuration(readTimeSeconds)
}

function traverseArticleNodesCountWords(
  contentItems: Array<Block | Inline | Text>,
) {
  let count = 0
  contentItems.forEach((contentItem: Block | Inline | Text) => {
    if (contentItem.nodeType === 'text' && contentItem.value) {
      const textValue = contentItem.value.trim()
      if (textValue.length) count += textValue.split(' ').length

      return
    }

    if ('content' in contentItem) {
      count += traverseArticleNodesCountWords(contentItem.content)
    }
  })

  return count
}

export function countArticleWords(content: ArticlePageContent) {
  let count = content.summary.split(' ').length
  content.blocks.forEach((block) => {
    if (block.type === 'RichText') {
      const richTextProps = block.props as RichTextProps
      count += traverseArticleNodesCountWords(richTextProps.richText.content)
    }
  })

  return count
}
