<template>
  <div v-click-outside="() => (menuOpen = false)">
    <div class="flex items-center">
      <p class="body-2 mr-4 hidden md:block">
        {{ i18n(translations.shareArticleLabel) }}
      </p>

      <div class="flex flex-col items-end md:items-center">
        <RevButtonIcon
          class="text-action-default-hi hover:bg-action-default-min-hover"
          data-test="article-share-icon"
          :icon="IconShare"
          size="small"
          variant="secondary"
          @click="onShareIconClick"
        />

        <ShareList
          v-if="menuOpen"
          class="mt-40 flex flex-col"
          @copy-clicked="openToast"
          @menu-open="(value: boolean) => (menuOpen = value)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { clickOutside as vClickOutside } from '@backmarket/utils/directives/ClickOutside'
import { RevButtonIcon } from '@ds/components/ButtonIcon'
import { IconCheckInCircleFilled } from '@ds/icons/IconCheckInCircleFilled'
import { IconShare } from '@ds/icons/IconShare'

import translations from './ShareArticle.translations'
import ShareList from './ShareList.vue'

const menuOpen = ref<boolean>(false)

const i18n = useI18n()
const route = useRoute()
const { trackClick } = useTracking()
const { openSuccessToast } = useTheToast()

function onShareIconClick() {
  menuOpen.value = !menuOpen.value

  trackClick({
    zone: `article_${route.params.pageName}`,
    name: 'ShareButton',
    article_category: route.params.category,
  })
}

function openToast() {
  openSuccessToast({
    title: i18n(translations.toastTitle),
    content: i18n(translations.toastDescription),
    closeAlternativeText: i18n(translations.toastCloseAlternativeText),
    duration: 3000,
    icon: IconCheckInCircleFilled,
    name: 'article-copy-url-toast',
  })
}
</script>
