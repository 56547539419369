import {
  getArticleContent,
  getArticleVotes,
} from '@backmarket/http-api/src/api-specs-content/endpoints'
import {
  type ApiArticlePageContent,
  type ArticlePageContent,
} from '@backmarket/http-api/src/api-specs-content/models/article-page-content'
import type { MappedApiPageContent } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

import { useFetchFormattedPageContent } from '~/scopes/cms/composables/useFetchFormattedPageContent'

import { usePageParamsFromRoute } from '../../../cms/composables/usePageParamsFromRoute'

function useFetchVotes() {
  const { pageName } = usePageParamsFromRoute()

  return useHttpFetch(getArticleVotes, {
    pathParams: { articleSlug: pageName },
  })
}

const formatUpdateDate = (
  response: MappedApiPageContent<ApiArticlePageContent>,
): ArticlePageContent => {
  const { updateDate, ...res } = response

  return {
    ...res,
    updateDate: updateDate ? new Date(updateDate) : undefined,
  }
}

function useFetchContent() {
  const pathParams = usePageParamsFromRoute()

  return useFetchFormattedPageContent(getArticleContent, {
    pathParams,
    transform: formatUpdateDate,
  })
}

export async function useFetchArticle() {
  const [fetchedContent, fetchedVotes] = await Promise.all([
    useFetchContent(),
    useFetchVotes(),
  ])

  return { fetchedContent, fetchedVotes }
}
