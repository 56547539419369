<template>
  <RevList
    class="bg-static-default-low border-static-default-low shadow-short rounded-sm absolute flex w-[240px] flex-col"
    :has-external-borders="false"
  >
    <RevListItemInteractive
      data-test="article-share-link"
      @click="onCopyLinkClick"
    >
      <template #label>
        {{ i18n(translations.copyLink) }}
      </template>
    </RevListItemInteractive>

    <RevListItemInteractive
      v-for="socialLink in socialLinks"
      :key="socialLink.label"
      :data-test="`article-share-${socialLink.value}`"
      target="_blank"
      :to="{ href: socialLink.link, type: 'external' }"
      @click="onShareClick(socialLink)"
    >
      <template #label>
        {{ socialLink.label }}
      </template>
    </RevListItemInteractive>
  </RevList>
</template>

<script setup lang="ts">
import { useRequestURL, useRoute } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevList } from '@ds/components/List'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'

import translations from './ShareArticle.translations'

type SocialLink = { label: string; link: string; value: string }

const i18n = useI18n()
const currentURL = useRequestURL()
const route = useRoute()
const { trackClick } = useTracking()

const emit = defineEmits<{
  'menu-open': [value: boolean]
  'copy-clicked': [value: void]
}>()

const socialLinks: SocialLink[] = [
  {
    label: i18n(translations.shareX),
    link: `https://x.com/intent/post?url=${currentURL.href}`,
    value: 'x',
  },
  {
    label: i18n(translations.shareFacebook),
    link: `https://www.facebook.com/sharer/sharer.php?u=${currentURL.href}`,
    value: 'facebook',
  },
  {
    label: i18n(translations.shareLinkedIn),
    link: `https://www.linkedin.com/sharing/share-offsite?url=${currentURL.href}`,
    value: 'linkedin',
  },
]

function onCopyLinkClick() {
  navigator.clipboard.writeText(currentURL.href)

  emit('menu-open', false)
  emit('copy-clicked')

  trackClick({
    zone: `article_${route.params.pageName}`,
    name: 'ShareButton_link',
    article_category: route.params.category,
  })
}

function onShareClick(socialLink: SocialLink) {
  emit('menu-open', false)

  trackClick({
    zone: `article_${route.params.pageName}`,
    name: `ShareButton_${socialLink.value}`,
    article_category: route.params.category,
  })
}
</script>
