import { useRouteLocationWithLocale, useRuntimeConfig } from '#imports'
import { type MaybeRefOrGetter, computed, toValue } from 'vue'

import type { ArticlePageContent } from '@backmarket/http-api/src/api-specs-content/models/article-page-content'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { CMS } from '../../../cms/routes-names'

export function useGetBreadcrumb(
  contentRef: MaybeRefOrGetter<ArticlePageContent | null>,
) {
  const { FF_ENABLE_NEW_CONTENT_HUB_API } = useRuntimeConfig().public
  const { market } = useMarketplace()
  const enableNewContentHubAPI = FF_ENABLE_NEW_CONTENT_HUB_API?.includes(
    market.countryCode,
  )

  return computed(() => {
    const content = toValue(contentRef)

    if (!content) {
      return {
        currentPageTitle: '',
        intermediaryPath: [],
      }
    }

    const resolveLocalizedRoute = useRouteLocationWithLocale()
    const { categories = [], titleBreadcrumb: currentPageTitle = '' } = content

    const intermediaryPath = categories.map(({ title, slug }) => {
      return {
        title,
        link: resolveLocalizedRoute({
          name: slug === 'content' ? CMS.ARTICLE_ROOT_HUB : CMS.ARTICLE_HUB,
          params: enableNewContentHubAPI
            ? { pageCategory: slug }
            : { unifiedName: slug },
        }),
      }
    })

    return {
      currentPageTitle,
      intermediaryPath,
    }
  })
}
