import { useHead, useRequestURL } from '#imports'
import { type MaybeRefOrGetter, toValue } from 'vue'

import type { ArticlePageContent } from '@backmarket/http-api/src/api-specs-content/models/article-page-content'
import type { ContentMediaProps } from '@backmarket/http-api/src/api-specs-content/models/content-media'
import type { MediaProps } from '@backmarket/http-api/src/api-specs-content/models/media'
import { insertIf } from '@backmarket/utils/collection/insertIf'

import {
  countArticleWords,
  wordCountToISO8601Duration,
} from '~/scopes/article/Article/components/ReadTime/ReadTime.utils'
import { prepareJsonLdScript } from '~/utils/seo/schema'

function getBannerAndBlockImages(article: ArticlePageContent) {
  const images = article.banner?.image ? [article.banner.image.src] : []

  const mediaBlocks = article.blocks.filter(
    (block) => block.type === 'Media' || block.type === 'ContentMedia',
  )

  for (const media of mediaBlocks) {
    const blockProps = media.props as MediaProps | ContentMediaProps
    if (!blockProps.image?.src) continue

    images.push(blockProps.image?.src)
  }

  return images
}
export function buildArticlePageStructuredData({
  article,
  currentURL,
}: {
  article: ArticlePageContent
  currentURL: URL
}) {
  const images = getBannerAndBlockImages(article)

  const keywords = article.keywords ? article.keywords.join(', ') : ''

  const wordCount = article.wordCount
    ? article.wordCount
    : countArticleWords(article)

  return {
    '@context': 'https://schema.org',
    '@type': 'TechArticle',
    headline: article.title,
    image: images,
    ...insertIf(!!article.author, {
      author: {
        '@type': 'Person',
        name: article.author?.props.name,
        jobTitle: article.author?.props.role,
      },
    }),
    genre: 'tech',
    publisher: {
      '@type': 'Organization',
      name: 'Back Market',
    },
    wordCount,
    timeRequired: wordCountToISO8601Duration(wordCount),
    ...insertIf(!!keywords, { keywords }),
    url: currentURL.href,

    // isoString date will look like yyyy-mm-ddThh:mm:ss.sssZ. This retrieves the yyyy-mm-dd part part
    dateModified: article.updateDate?.toISOString().split('T')[0],
  }
}

/**
 * sets structured data through a ld+json script in the head
 * See more info about ld+json:
 * @doc https://developers.google.com/search/docs/appearance/structured-data/intro-structured-data
 * @doc https://json-ld.org/
 */
export function useArticlePageStructuredData(
  articleRef: MaybeRefOrGetter<ArticlePageContent | null>,
) {
  const article = toValue(articleRef)
  if (!article) return

  const currentURL = useRequestURL()

  const structuredData = buildArticlePageStructuredData({
    article,
    currentURL,
  })

  useHead({
    script: [prepareJsonLdScript(structuredData)],
  })
}
