<template>
  <div v-if="updateDate" class="text-static-default-mid body-1">
    <p class="hidden md:block">
      {{
        i18n(translations.articleUpdateDate, {
          date: i18n.date(updateDate, {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }),
        })
      }}
    </p>

    <p class="block md:hidden">
      {{
        i18n.date(updateDate, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './PublicationUpdateDate.translations'

defineProps<{ updateDate?: Date }>()

const i18n = useI18n()
</script>
